import request from "@/utils/request";

/* 退出登录 */
export function loginOut(params) {
  return request({
    url: `/console/authorizations/${params.id}`,
    method: 'delete',
  });
}
/* 修改密码 */
export function changePassword(params) {
  return request({
    url: '/console/authorizations/reset_password',
    method: 'patch',
    data: params,
  });
}
