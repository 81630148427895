import "core-js/modules/es.string.link.js";
import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-49d906a4"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "top"
};
var _hoisted_2 = {
  key: 0,
  class: "left"
};
var _hoisted_3 = {
  class: "navbar"
};
var _hoisted_4 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.onlyUserShow ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navbar, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["nav-item", {
        active: _ctx.curPath === item.link
      }]),
      key: index,
      onClick: function onClick($event) {
        return _ctx.switchTab(item.link);
      }
    }, _toDisplayString(item.name), 11, _hoisted_4);
  }), 128))])])) : _createCommentVNode("", true)]);
}