import request from "@/utils/request";

/* 登录 */
export function loginIn(params) {
  return request({
    url: '/console/authorizations',
    method: 'post',
    data: params,
  });
}
/* 发送短信 */
export function postSms(params) {
  return request({
    url: '/system/sms_codes',
    method: 'post',
    data: params,
  });
}

/* 验证码登录 */
export function codeLogin(params) {
  return request({
    url: '/console/authorizations/phone_login',
    method: 'post',
    data: params,
  });
}

/* 验证码登录 */
export function test(params) {
  return request({
    url: '/restful/getIDPToken',
    method: 'get',
    params,
  });
}
